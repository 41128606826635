@font-face {
    font-family: 'latoblack';
    src: url('#{$fonts}/lato_black/lato-black-webfont.eot');
    src: url('#{$fonts}/lato_black/lato-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/lato_black/lato-black-webfont.woff2') format('woff2'),
         url('#{$fonts}/lato_black/lato-black-webfont.woff') format('woff'),
         url('#{$fonts}/lato_black/lato-black-webfont.ttf') format('truetype'),
         url('#{$fonts}/lato_black/lato-black-webfont.svg#latoblack') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'lato-bold';
    src: url('#{$fonts}/lato-bold/lato-bold-webfont.eot');
    src: url('#{$fonts}/lato-bold/lato-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/lato-bold/lato-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/lato-bold/lato-bold-webfont.woff') format('woff'),
         url('#{$fonts}/lato-bold/lato-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/lato-bold/lato-bold-webfont.svg#latobold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latoitalic';
    src: url('#{$fonts}/lato_italic/lato-italic-webfont.eot');
    src: url('#{$fonts}/lato_italic/lato-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/lato_italic/lato-italic-webfont.woff2') format('woff2'),
         url('#{$fonts}/lato_italic/lato-italic-webfont.woff') format('woff'),
         url('#{$fonts}/lato_italic/lato-italic-webfont.ttf') format('truetype'),
         url('#{$fonts}/lato_italic/lato-italic-webfont.svg#latoitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latoregular';
    src: url('#{$fonts}/lato_regular/lato-regular-webfont.eot');
    src: url('#{$fonts}/lato_regular/lato-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/lato_regular/lato-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/lato_regular/lato-regular-webfont.woff') format('woff'),
         url('#{$fonts}/lato_regular/lato-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/lato_regular/lato-regular-webfont.svg#latoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratblack';
    src: url('#{$fonts}/montserrat-black/montserrat-black-webfont.eot');
    src: url('#{$fonts}/montserrat-black/montserrat-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.svg#montserratblack') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OFFISFB';
    src: url('#{$fonts}/officina-serif-itc-bold/OFFISFB.eot');
    src: url('#{$fonts}/officina-serif-itc-bold/OFFISFB.eot') format('embedded-opentype'),
         url('#{$fonts}/officina-serif-itc-bold/OFFISFB.woff2') format('woff2'),
         url('#{$fonts}/officina-serif-itc-bold/OFFISFB.woff') format('woff'),
         url('#{$fonts}/officina-serif-itc-bold/OFFISFB.ttf') format('truetype'),
         url('#{$fonts}/officina-serif-itc-bold/OFFISFB.svg#OFFISFB') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotoregular';
    src: url('#{$fonts}/roboto_regular/roboto-regular-webfont.eot');
    src: url('#{$fonts}/roboto_regular/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.woff') format('woff'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OFFISFW';
    src: url('#{$fonts}/officina-serif-itc/OFFISFW.eot');
    src: url('#{$fonts}/officina-serif-itc/OFFISFW.eot') format('embedded-opentype'),
         url('#{$fonts}/officina-serif-itc/OFFISFW.woff2') format('woff2'),
         url('#{$fonts}/officina-serif-itc/OFFISFW.woff') format('woff'),
         url('#{$fonts}/officina-serif-itc/OFFISFW.ttf') format('truetype'),
         url('#{$fonts}/officina-serif-itc/OFFISFW.svg#OFFISFW') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'museo700';
    src: url('#{$fonts}/museo_700/museo700-regular-webfont.eot');
    src: url('#{$fonts}/museo_700/museo700-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/museo_700/museo700-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/museo_700/museo700-regular-webfont.woff') format('woff'),
         url('#{$fonts}/museo_700/museo700-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/museo_700/museo700-regular-webfont.svg#museo700') format('svg');
    font-weight: normal;
    font-style: normal;
}