.carousel-indicators-custom{
	margin:0;
	margin-bottom:10px;

	@include media-breakpoint-up(lg){
		margin-bottom:60px;
	}

	li{
		height:9px;
		width:54px;
		background-color:#A8A8A8;
		opacity:1;
		border-radius:6px;
		display:block;
		border:none;
		transition:background-color 0.6s linear;
		@include margin(0,3);
		position:relative;

		@include beforeAfter{
			content:'';
			display:block;
			position:absolute;
			left:0;
			width:100%;
			height:100%;
		}

		&:before{
			bottom:100%;
		}

		&:after{
			top:100%;
		}

		&.active{
			background-color:#1A1A1A;
		}

	}

}