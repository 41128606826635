.card-objetivos{

	.numero-card{
		font-size:21px;
		font-family: 'lato-bold';
		width:(68 / 21) * 1em;
		height:(68 / 21) * 1em;
		line-height:(68 / 21) * 1em;
		background-color:$theme2;
		text-align:center;
		border-radius:100%;
		box-shadow:0 0 8px rgba(#000,0.5);
		margin-bottom:8px;
		overflow:hidden;
		white-space:nowrap;

		@include media-breakpoint-down(md){
			margin-left:auto;
			margin-right:auto;
		}

	}

	.title-card{
		font-size:20px;
		font-family: 'latoblack';
		margin-bottom:6px;
	}

	.desc{
		max-height:110px;
		overflow:hidden;
		line-height:1.1;
	}

}