.btn-pill{
	border-radius: 2rem;
}

.btn-expanded{
	padding:.375rem 1.5rem;
}

.btn{

	&.btn-min-width-212{
		min-width:212px;
	}

	&.btn-radius-30{
		border-radius:30px;
	}

	&.border-3-width{
		border-width:3px;
	}

	&.btn-pdg-17-20{
		padding:17px 20px;
	}

	&.btn-theme-claro{
		@include button-variant(#91C501,#91C501);
		color:#FFF;
		
		@include hover-focus{
			@include button-variant(lighten($theme,10%),#b6e024);
			color:#b6e024 !important;
		}

	}

}