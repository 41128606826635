.info-rodape{

	@include media-breakpoint-down(lg){
		background-color:$theme2;
	}
	
	@include media-breakpoint-up(xl){
		position:absolute;
		bottom:100%;
		left:0;
		width:100%;
		min-height:534px;
	}

	.content{

		@include media-breakpoint-up(xl){
			@include make-container();
			@include make-container-max-widths();
		}

		.box-info{
			width:100%;
			margin-left:0;
			background-color:$theme2;
			text-align:center;
			padding-left:50px;
			padding-right:42px;
			padding-top:32px;
			padding-bottom:40px;
			min-height:536px;
			z-index:800;
			position:relative;
			max-width:545px;

			@include media-breakpoint-down(lg){
				@include make-container();
				@include make-container-max-widths();
			}

			.dados{
				border-top:4px solid lighten($theme2,10%);
				border-bottom:4px solid lighten($theme2,10%);
				padding-top:20px;
				padding-bottom:2px;
				margin-bottom:20px;

				.info{
					margin-bottom:22px;
					color:#FFF;
					font-size:15px;
					font-family: 'lato-bold';

					&:before{
						content:'';
						display:block;
						margin-left:auto;
						margin-right:auto;
						margin-bottom:8px;
						width:11px;
						height:11px;
						background-color:#FFF;
						border-radius:100%;
					}

					.title{
						display:block;
						line-height:1;
						margin-bottom:2px;
					}

					span{


						& + span{

							&:before{
								content:'|';
								display:inline-block;
								margin:0 6px;
							}

						}

					}

					&.info-endereco{

						span{
							max-height:43px;
							overflow:hidden;
							display:block;
						}

					}

				}

			}

		}

	}

}