.card-parceiros{
	padding-top:12px;

	.card-header{
		max-width:170px;
		width:100%;
		min-height:128px;
		box-shadow:0 0 16px rgba(#000,0.3);
		display:flex;
		flex-wrap:wrap;
		justify-content:center;
		align-items:center;
		margin-bottom:40px;
		background-color:#FFF;
		margin-left:auto;
		margin-right:auto;
	}

}