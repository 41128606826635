.media-incrivel{
	margin-bottom:10px;

	@include media-breakpoint-up(lg){
		display:flex;
		flex-wrap:wrap;
		margin-bottom:0;
	}

	.media-icon{
		margin-bottom:6px;

		@include media-breakpoint-up(lg){
			margin-right:6px;
			margin-bottom:0;
		}

	}

	.media-text{

		span{

			& + span{

				&:before{
					content:'|';
					margin:0 4px;
				}

			}

		}

	}

}