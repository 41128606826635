.rodape{

	.embed-responsive{
		z-index:700;
	}

	.rodape-copyright{
		font-size:10px;
		padding-bottom:24px;
		font-family: 'lato-bold';
		color:#000;
		text-align:center;
		position:relative;

		.container{

			@include media-breakpoint-down(lg){
				padding-top:40px;
			}

		}

		@include media-breakpoint-up(xl){
			padding-top:40px;
		}

		.gv8{

			img{
				max-width:44px;
			}

		}

	}

}