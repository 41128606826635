.topo{
	box-shadow:0 0 6px rgba(#000,0.3);
	position:relative;
	z-index:2000;

	.bg-menu{
		opacity:0;
		position:fixed;
		top:0;
		left:0;
		width:100%;
		height:0;
		background-color:rgba(#000,0.5);
		transition:opacity 0.4s ease;
	}

	@include media-breakpoint-up(lg){
		padding-bottom:20px;
	}

	.bar-topo{
		background-color:$theme;
		color:#FFF;
		font-family: 'latoitalic';
		font-size:14px;
		text-align:center;

		@include media-breakpoint-down(md){
			display:none;
		}

		@include media-breakpoint-up(lg){
			text-align:left;
			color:transparent;
			background-color:#FFF;
			visibility:hidden;
		}

		.container{

			@include media-breakpoint-up(lg){
				display:flex;
				flex-wrap:wrap;
				justify-content:space-between;
				align-items:center;
			}

			& > *{
				padding-top:9px;
				padding-bottom:9px;

				@include media-breakpoint-up(lg){
					max-width:50%;
				}

			}

		}

	}

	.nav-content{
		
		@include media-breakpoint-up(lg){
			@include make-container();
			@include make-container-max-widths();
		}

		@include media-breakpoint-down(md){
			position:fixed;
			top:0;
			left:-230px;
			width:230px;
			height:100%;
			z-index:1000;
			overflow:auto;
			border-right:1px solid $theme;
			background-color:#FFF;
			transition:left 0.6s linear;
		}

	}

	&.fx{

		.mbl-controls{
			position:fixed;
			top:0;
			left:0;
			width:100%;
			background-color:#FFF;
			box-shadow:0 0 8px rgba(#000,0.5);
			animation:fadeInDown 0.6s linear;
			z-index:600;
			max-width:100%;
		}

	}

}

body{

	&.sombra{
	
		.bg-menu{
			height:100%;
			opacity:1;
			z-index:800;
		}

	}

	&.menu-active{

		.topo{

			.nav-content{
				left:0;
			}

		}

	}

}