.menu-topo{
	list-style-type:none;
	padding-left:0;
	margin-bottom:0;
	font-family: 'lato-bold';
	color:#26262B;
	font-size:14px;
	font-weight:bold;
	display:flex;
	flex-wrap:wrap;

	@include media-breakpoint-up(lg){
		justify-content:space-between;
		align-items:flex-end;
		width:100%;
	}

	& > li{
		display:block;

		@include media-breakpoint-down(md){
			order:2;
			width:100%;
		}

		&.li-imagem{

			@include media-breakpoint-down(md){
				order:1;
				padding:15px;
				margin-bottom:15px;
			}
			
			@include media-breakpoint-up(lg){
				min-height:90px;
				min-width:232px;
				position:relative;
				margin-bottom:0;
				margin-left:20px;
				margin-right:20px;

				img{
					position:absolute;
					top:-28px;
					left:0;
					width:100%;
				}
			}

		}

		& > a{

			@include media-breakpoint-down(md){
				display:block;
				padding:10px 15px;
				border-top:1px solid transparent;
				border-bottom:1px solid transparent;
			}
			
			@include media-breakpoint-up(lg){
				display:block;
				padding-top:9px;
				padding-bottom:19px;
				position:relative;

				&:before{
					content:'';
					display:block;
					top:0;
					left:50%;
					transition:transform 0.6s ease;
					transform:translateX(-50%) scale(0);
					position:absolute;
					width:8px;
					height:8px;
					border-radius:100%;
					background-color:$theme2;
				}

			}

		}

		&.active,
		&:hover{

			& > a{

				@include media-breakpoint-down(md){
					background-color:$theme;
					color:#FFF;
					border-color:darken($theme,15%);
				}

				@include media-breakpoint-up(lg){
					color:$theme2;

					&:before{
						transform:translate(-50%) scale(1);
					}
					
				}

			}

		}

	}

}