.banner{

	.carousel-item{
		margin-bottom:0;
	}

}

.quem-somos{
	padding-top:25px;
	padding-bottom:30px;
	background-color:$theme;
	color:#FFF;
	position:relative;
	text-align:center;

	@include media-breakpoint-up(lg){
		text-align:left;
	}

	&:before{
		content:'';
		display:block;
		background-color:#91C501;
		width:100%;
		z-index:0;
		position:absolute;
		bottom:0;
		left:0;
		height:288px;
		z-index:4;
	}

	.col-texto{
		padding-top:32px;
		padding-bottom:32px;
	}

	.col-figure{

		.figure-especial{
			position:relative;
			z-index:60;

			@include media-breakpoint-down(md){

				img{
					@include img-fluid();
				}

			}

			@include media-breakpoint-up(lg){

				.box-encaixe-img{
					height:100%;
					width:100%;
					width:505px;
					min-height:655px;
					overflow:hidden;
					position:relative;

					img{
						z-index:-2;
						position:absolute;
						top:0;
						left:0;
						width:100%;
						height:100%;
					}

				}

			}

		}

	}

	.box-content-carousels{
		padding-top:25px;
		position:relative;

		@include media-breakpoint-up(lg){
			min-height:288px;
		}

		.title-custom{
			line-height:1;
			font-family: 'montserratblack';
			position:relative;
			margin-bottom:20px;
			color:rgba(#FFF,0.5);
			font-size:40px;

			@include media-breakpoint-up(lg){
				font-size:110px;
				position:absolute;
				left:84px;
				margin-bottom:0;
				color:rgba(#FFF,0.04);
				z-index:2;
				top:0;
			}

		}

		.slick-slider{
			z-index:20;
		}

		.clearfix{
			font-size:18px;
			text-align:center;
			color:#326806;
			font-weight:bold;
			max-width:36px;
			margin-left:auto;
			margin-right:auto;
			padding-top:22px;

			& > *{
				@include margin(0,4);
				position:relative;
				z-index:500;

				@include media-breakpoint-down(md){
					color:$theme2;
				}

				@include hover-focus{
					color:#FFF;
				}

			}

		}

	}

	.slick-slider{

		.slick-track{

			& > *{
				
				@include media-breakpoint-up(md){
					@include margin(0,15);
					overflow:hidden;
					padding-top:15px;
					padding-left:15px;
				}

			}

		}

	}

}

.servicos{

	@include media-breakpoint-down(md){
		padding-bottom:60px;
	}
	
	.box-dual-section{

		.parte-imagem{
			background-repeat:no-repeat;
			background-size:cover;
			background-position:right;
			min-height:827px;

			@include media-breakpoint-up(lg){
				width:(752 / 1920) * 100%;
			}

		}

		.parte-text{
			width:100%;

			@include media-breakpoint-down(md){
				padding-top:20px;
				@include make-container();
				@include make-container-max-widths();
			}
		
			@include media-breakpoint-up(lg){
				width:(1168 / 1920) * 100%;
				display:flex;
				align-items:center;
				flex-direction:column;
				max-width:751px;
				padding-left:25px;
				padding-right:25px;
				justify-content:center;
			}

			.box-controls-carousel{
				position:relative;

				@include media-breakpoint-up(lg){
					
					&:before{
						content:'';
						position:absolute;
						top:-32px;
						left:190px;
						display:block;
						height:calc(100% + 64px);
						background-color:$theme2;
						background-size:cover;
						background-repeat:no-repeat;
						background-image:url('../images/bg-nossos-servicos.jpg');
						width:60vw;
						z-index:-2;
					}

				}

			}

			.carousel-controls{
				width:100%;
				height:100%;
				display:flex;
				flex-wrap:wrap;
				align-items:center;
				justify-content:center;
				margin-bottom:30px;

				@include media-breakpoint-up(lg){
					position:absolute;
					top:0;
					left:0;
					margin-bottom:0;
				}

				.clearfix{
					width:100%;

					& > *{
						z-index:200;
						position:relative;
						font-size:24px;
						width:(52 / 24) * 1em;
						height:(52 / 24) * 1em;
						line-height:(52 / 24) * 1em;
						background-color:lighten($theme,20%);
						border-radius:100%;
						text-align:center;
						color:#FFF;

						@include hover-focus{
							background-color:$theme;
						}

					}

					.fa-chevron-left{

						@include media-breakpoint-up(lg){
							left:-51px;
						}

					}

					.fa-chevron-right{

						@include media-breakpoint-up(lg){
							right:-26px;
						}

					}

				}

			}

		}

	}

}

.parceiros{
	position:relative;

	@include media-breakpoint-down(md){
		padding-bottom:40px;
	}

	.title{

		@include media-breakpoint-down(md){
			font-size:30px !important;
			margin-bottom:30px;
		}

		@include media-breakpoint-up(lg){
			margin-top:-26px;
			margin-bottom:0;
		}

	}

	.slick-slider{

		@include media-breakpoint-up(lg){
			margin-top:-40px;
		}

	}

}

.atuacao{
	padding-top:2px;
	padding-bottom:42px;
	position:relative;
	z-index:55;
	text-align:center;

	@include media-breakpoint-up(xl){
		text-align:left;

		&:before{
			content:'';
			display:block;
			position:absolute;
			bottom:0;
			left:0;
			background-color:$theme;
			width:100%;
			height:570px;
		}

	}

	.figure-especial{
		
		@include media-breakpoint-up(lg){
			min-height:730px;
		}

		img{

			@include media-breakpoint-down(md){
				@include img-fluid();
			}

		}

	}

}