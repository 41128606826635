@import "_fontfaces";

body{
	color:#6D6D6D;
	font-size:15px;
	font-family: 'latoregular';
}

// FONTS USADAS
#app{
	position:relative;
	overflow:hidden;
}

.topo a,
.rodape a{
	word-break:break-all;
}

.logo{
	margin-bottom:0;
}

.internas img{
	@include img-fluid();
}

a,
.btn{
	transition:all 0.6s linear;
}

a{
	color:inherit;
	text-decoration:none;

	@include hover-focus{
		color:inherit;
		text-decoration:none;
	}

}

*[data-animate]{
	opacity:0;
}

.animated{
	animation-direction:1.4s;
	animation-fill-mode: both;
}

.animated[data-animate]{
	opacity:1;
}

.atraso-1{
	animation-delay:0;
}

.atraso-2{
	animation-delay:0.2s;
}

.atraso-3{
	animation-delay:0.4s;
}

.atraso-4{
	animation-delay:0.6s;
}

lazy-image{
	background-color:transparent;
}

.slick-slider{

	img{
		display:inline-block !important;
	}

}

.carousel{
	text-align:center;

	.carousel-item{
		position: relative;
		z-index:200;
	}

}

:focus{
	outline:none !important;
}

.slick-slider{

	img{
		@include img-fluid();
	}

}

.text-272727{
	color:#272727 !important;
}


// FONTS USADAS

.ofis{
	font-family: 'OFFISFB';
}

.ofis-w{
	font-family: 'OFFISFW';
}

.lato-blk{
	font-family: 'latoblack';
}

.lato-bld{
	font-family: 'lato-bold';
}

.lato-itc{
	font-family: 'latoitalic';
}

.lato-reg{
	font-family: 'latoregular';
}

.mont-blk{
	font-family: 'montserratblack';
}

.roboto-reg{
	font-family: 'robotoregular';
}

.embed-responsive{

	&.embed-responsive-1920x440{
		min-height:440px;

		&:before{
			padding-top:(440 / 1920) * 100%;
		}

	}

}

// concerta erro meu (João) de sprites em outros projetos
.icon{

	@each $icon,$value in $icons {
		&.#{$icon} {
			width: nth($value,3);
			height: nth($value,4);
			background-position: #{nth($value,1)} #{nth($value,2)};
		}
	}
}

.fnt-15{
	font-size:15px !important;
}

.fnt-17{
	font-size:17px !important;
}

.fnt-25{
	font-size:25px !important;
}

.fnt-104{
	font-size:104px !important;
}

.text-theme-lighten{
	color:#b6e024 !important;
}

.text-gray{
	color:#e7e7e7 !important;
}

.line-height-1{
	line-height:1;
}

.min-lg-height-374{

	@include media-breakpoint-up(lg){
		min-height:374px;
	}

}

.max-height{
	overflow:hidden;

	// &.max-height-50{
	// 	max-height:50px;
	// }

	&.max-height-60{
		max-height:60px;
	}

	&.max-height-80{
		max-height:80px;
	}

	&.max-height-180{
		max-height:180px;
	}

}

svg{
	border:none;
	display:block;
	box-shadow:none;
}

.cliente{
	text-align:center;
}

.vertical-align-bottom{
	vertical-align:bottom;
}