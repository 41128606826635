.card-atuacao{
	margin-bottom:30px;
	position:relative;

	& > *{
		margin-bottom:15px;
	}

	@include media-breakpoint-up(xl){
		display:flex;
		flex-wrap:wrap;
	}

	.box-icon{
		position:relative;
		max-width:80px;

		@include media-breakpoint-down(lg){
			margin-left:auto;
			max-height:80px;
			display:flex;
			align-items:center;
			justify-content:center;
			margin-right:auto;
			background-color:$theme;
			padding-top:10px;
			padding-bottom:10px;
			border-radius:100%;
		}

		img{
			z-index:20;
			position:relative;
		}

		@include media-breakpoint-up(xl){
			margin-right:9px;

			&:before{
				content:'';
				position:absolute;
				top:11px;
				left:0;
				width:44px;
				height:44px;
				background-color:$theme2;
				border-radius:100%;
				z-index:2;
				transition:background-color 0.6s linear;
			}

		}

	}

	.box-text{

		@include media-breakpoint-up(xl){
			max-width:270px;
		}

		.title,
		.desc{
			overflow:hidden;
		}

		.title{
			color:#FFF;
			font-size:17px;
			font-family: 'latoblack';
			margin-bottom:4px;
			line-height:1;
			transition:color 0.6s linear;
			max-height:34px;

			@include media-breakpoint-down(lg){
				color:$theme;
			}

		}

		.desc{
			color:#d1d1d1;
			max-height:70px;

			@include media-breakpoint-down(lg){
				color:#222;
			}

		}

	}

	&:hover{

		.box-text{

			.title{
				color:#8FC400;

				@include media-breakpoint-down(lg){
					color:$theme2;
				}

			}

		}

		.box-icon{

			&:before{
				background-color:#8FC400;
			}

		}

	}

}