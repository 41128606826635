.redes-sociais{

	&.redes-sociais-rodape{

		& > *{

			@include margin(0,2);
			font-size:14px;
			width:(50 / 14) * 1em;
			height:(50 / 14) * 1em;
			line-height:(50 / 14) * 1em;
			background-color:#FFFFFF;
			color:#000;
			border-radius:100%;

			@include hover-focus{
				background-color:$theme;
				color:#FFF;
			}
			
		}

	}

}