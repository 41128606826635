.card-servicos{
	padding-top:32px;
	padding-right:30px;
	padding-left:20px;
	padding-bottom:3px;
	background-color:#FFF;
	position:relative;

	@include media-breakpoint-up(lg){
		margin-bottom:64px;
	}

	.row{

		& > *{
			margin-bottom:24px;
		}

	}

	.card-desc{
		
		@include media-breakpoint-up(lg){
			text-align:right;
		}

		.title,
		.desc{
			overflow:hidden;
		}

		.title{
			font-size:20px;
			font-family: 'latoblack';
			color:$theme2;
			margin-bottom:3px;
			max-height:60px;
		}

		.desc{
			font-size:15px;
			line-height:1.1;
			max-height:180px;
		}

	}

}